.agences__container-menu__banner {
  @apply min-w-[40vw] max-w-[40vw] h-screen relative origin-right;
  img {
    @apply absolute h-full w-full object-cover -z-10;
  }
  &-content {
    @apply w-full h-full flex flex-col items-center justify-center gap-7;
    h2 {
      @apply text-white font-semibold text-3xl drop-shadow-big;
    }
    p {
      @apply text-center text-white drop-shadow-big;
    }

    button {
      @apply bg-[#ffffffdc] px-10 py-5 rounded-full;
      span {
        @apply text-[#383838] drop-shadow-2xl;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .agences__container-menu__banner {
    @apply mb-8 hidden;
    &-content {
      @apply gap-2;
      h2,
      p {
        @apply text-black drop-shadow-none;
      }
      button {
        @apply bg-[#000000dc] px-10 py-4 rounded-full;
        span {
          @apply text-[#ffffff] drop-shadow-2xl;
        }
      }
    }
    img {
      @apply hidden;
    }
    // @apply h-[332px];
  }
}

@media screen and (max-width: 520px) {
  .agences__container-menu__banner {
    &-content {
      @apply gap-5;
      h2 {
        @apply self-start;
      }
      p {
        @apply text-left font-light text-sm leading-6;
      }
      button {
        @apply self-start rounded-none px-5 py-4 text-sm;
      }
    }
  }
}
