.agences__container-menu__left {
  @apply p-8 min-w-[50vw] max-w-[50vw]  grid-cols-1 grid-flow-row justify-center content-center gap-8 items-center origin-right;

  &-item {
    @apply w-[100%] flex flex-row items-center gap-4 cursor-pointer;
    &:nth-child(even) {
      @apply flex-row-reverse pl-4;
      img {
        @apply rounded-none rounded-r-xl;
      }
    }
    img {
      @apply w-2/5 h-[150px] object-cover rounded-l-xl;
    }
    &__container {
      @apply flex-1 flex flex-col gap-[2px] items-start;
      h2 {
        @apply text-xl mb-auto italic font-medium;
      }
      p {
        @apply text-xs font-normal;
      }
      button {
        @apply font-semibold text-blue-500;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .agences__container-menu__left {
    @apply min-w-[40vw] max-w-[100%] border-none;
    &-item {
      @apply flex-col items-start border-[1px] rounded-xl;
      &__container {
        @apply px-4 py-4;
      }

      &:nth-child(even) {
        @apply flex-col pl-0;
        img {
          @apply rounded-t-xl rounded-b-none;
        }
      }
      img {
        @apply w-full rounded-t-xl rounded-b-none;
      }
    }
  }
}
