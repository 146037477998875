.CatalogueDetail {
  @apply w-screen h-screen relative flex items-center justify-center;
  &::before {
    @apply content-[""] absolute top-0 left-0 w-full h-1/2 bg-[#D9E5E1] -z-10;
  }
  &-container {
    @apply w-[85vw] h-[75vh] bg-white drop-shadow-catalogue pt-4 flex flex-row items-start;
    &-text {
      @apply basis-2/5 h-full pl-20 flex flex-col justify-start;
      .back-btn {
        @apply text-sm underline text-green;
      }
      h1 {
        @apply font-semibold text-3xl ml-8 mt-12;
      }
      h2 {
        @apply font-medium text-2xl ml-8;
        &:nth-of-type(1) {
          @apply mt-8;
        }
      }
      p {
        @apply text-xs ml-8 w-3/4 mt-4 text-grey font-light leading-5;
      }
    }
    &-book {
      @apply flex-1 h-full flex items-center justify-center;
      .homePage {
        @apply w-[300px] h-[300px] bg-[#FFF] border-r-2 flex items-center justify-center;
        img {
          @apply w-3/4 object-cover mt-16 relative left-1/2 -translate-x-1/2;
        }
        h2 {
          @apply text-center font-extrabold text-xl border-b-[1px] border-b-black mx-2 pb-4;
        }
        p {
          @apply mt-2 text-center font-light text-sm mx-1;
        }
      }
      .endingPage {
        @apply w-[300px] h-[300px] bg-[#D9E5E1] flex items-center justify-center;
        h2 {
          // @apply;
        }
      }
      .demoPage {
        // @apply border-2;
        img {
          @apply w-full h-full object-cover;
        }
      }
    }
  }
}
