.hero__container {
  @apply mx-auto flex flex-col sm:px-24 px-12 min-h-screen;

  &-header {
    @apply flex flex-row flex-1 items-start justify-between;
    &-logo {
      @apply w-[254px] object-cover;
    }
    &__nav {
      @apply flex flex-row flex-wrap justify-end max-md:hidden items-center gap-x-12;
      &-item {
        transition: all 0.3s;
        @apply flex flex-row 
            items-center gap-2 
            hover:border-b-[1px] 
            hover:border-b-[var(--color-black)] 
            hover:scale-[1.1]
            h-10 hover:cursor-pointer;
        &-text {
          @apply text-sm;
        }
      }
    }
  }

  &-body {
    @apply flex flex-row flex-grow-[6] justify-between min-h-full;
    &__text-container {
      @apply flex flex-col flex-[1.5] justify-evenly;
      h1 {
        @apply lg:text-[32px] lg:leading-[56px] text-3xl font-bold w-full;
      }
      &-icon-container {
        &::before {
          content: "";
          @apply content-[""] relative w-14 h-[1px] bg-black;
        }
        @apply flex items-center gap-4;
        .icons:hover {
          cursor: pointer;
          transition: color 0.5s;
          color: rgb(0, 236, 201);
        }
      }
    }
    &__img-container {
      @apply flex-1 grid grid-cols-3 grid-rows-1  items-end justify-between;
      &__content {
        @apply w-[140px] overflow-hidden rounded-3xl bg-red-50;
        &-item {
          @apply w-full h-full object-cover transition-transform duration-700 hover:scale-150;
        }
      }
    }
  }
}
@media screen and (max-width: 1260px) {
  .hero__container-body__img-container {
    @apply grid-cols-2 grid-rows-2 content-center items-center gap-x-2;
    &__content {
      @apply h-[200px] w-full;
      &:nth-child(3) {
        @apply col-span-full;
      }
      img {
        @apply w-full h-full;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .hero__container-body {
    @apply flex-col mt-16;
    &__text-container {
      @apply justify-between gap-5;
      h1 {
        @apply text-2xl font-semibold;
      }
    }
    &__img-container {
      @apply mt-20;
      @apply flex-1 grid grid-cols-1 grid-rows-3 items-end justify-between gap-y-4;
      &__content {
        @apply w-full h-[130px] overflow-hidden rounded-3xl bg-red-50;
        &:nth-child(3) {
          @apply col-span-1;
        }
        &-item {
          @apply w-full h-full object-cover transition-transform duration-700 hover:scale-150;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .hero__container-body__text-container {
    @apply gap-10;
  }
}

@media screen and (max-width: 520px) {
  .hero__container-body__text-container {
    h1 {
      @apply flex flex-row flex-wrap;
    }
    @apply gap-10;
  }
}
