.Equipes {
  @apply w-screen min-h-screen flex flex-col items-center py-12;
  &-icon {
    @apply text-[#232C29] text-5xl;
  }
  &-title {
    @apply text-4xl text-[#232C29] font-light opacity-80;
  }
  &-subtitle {
    @apply text-center leading-6 text-[#232C29] opacity-80 font-light;
  }
  &-list {
    @apply w-full sm:w-5/6 h-3/4  grow sm:px-4 py-2 grid grid-rows-1 grid-flow-col justify-center gap-4 overflow-y-hidden overflow-x-scroll;
  }
}
