.ReferenceDetailProjection {
  @apply row-start-2 row-end-4 col-start-3 col-end-6 p-6 flex flex-col gap-2;
  h2 {
    @apply text-base;
  }
  &-phaseContainer {
    @apply flex flex-row items-center gap-4 text-sm mb-4;
    p {
      @apply text-green cursor-pointer duration-500;
      &.active {
        @apply text-black;
      }
    }
  }
  &-illustrationContainer {
    @apply overflow-hidden flex flex-row flex-wrap sm:grid grid-rows-2 grid-flow-col sm:gap-4;
    & > div {
      @apply w-full h-1/5;
      img {
        @apply object-cover w-full h-full;
      }
    }
  }
  .backBtn {
    @apply block sm:hidden self-start mt-auto mb-8 text-grey underline;
  }
}
