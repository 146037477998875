.ReferenceDetail {
  @apply w-screen flex flex-col sm:grid sm:grid-cols-5 sm:grid-rows-3 overflow-x-hidden;
  & > div {
    // @apply border-2;
    &:nth-child(1) {
      @apply order-1;
    }

    &:nth-child(2) {
      @apply order-2;
    }
    &:nth-child(3) {
      @apply order-3;
    }
  }
}
