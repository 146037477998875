.EcoCitoyens {
  @apply px-6 sm:px-8;
  @apply mt-8 sm:mt-0 min-h-screen flex flex-col sm:flex-row items-center justify-around;

  img {
    @apply w-8/12 sm:w-3/12 object-cover;
  }
  .EcoCitoyens-content {
    @apply w-full sm:w-7/12 flex flex-col items-start gap-12;
    h2 {
      @apply flex items-center text-[35px] sm:text-[49px];
      font-weight: 300;

      text-align: left;
      color: #000;

      & > span {
        .letter {
          &:nth-child(1) {
            color: #00ae73;
          }
          &:nth-last-child(1) {
            color: #00ae73;
          }
        }
      }
    }
    & > p {
      @apply px-4 py-2;
      border-radius: 5px;
      background: rgba(0, 174, 115, 0.43);
      font-weight: 600;
      font-size: 16px;
      text-align: left;
      color: #fff;
    }

    .EcoCitoyens-listes {
      @apply w-full grid grid-cols-1 sm:grid-cols-2 grid-flow-row gap-x-8 gap-8;
      li {
        @apply flex flex-col items-start gap-2;
        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          text-align: left;
          color: #0d0f0e;
        }
        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
          text-align: left;
          color: #5a8073;
        }
      }
    }
  }
}
