.Catalogues {
  @apply w-screen h-screen flex flex-col sm:grid sm:grid-rows-1 sm:grid-flow-col px-4 sm:pl-32 sm:pr-16 gap-4 sm:pt-[10%] items-start;
  &__text-container {
    @apply flex flex-col items-center sm:items-start;
    h2 {
      @apply text-4xl text-center sm:text-left font-medium mb-8;
    }
    p {
      @apply w-[90%] font-poppins text-justify sm:text-left text-sm tracking-wider leading-6 text-grey;
      white-space: pre-line;
    }
  }
  &:has(&__image-container:hover) &__image-container {
    @apply opacity-30 hover:opacity-100;
    &:hover {
      box-shadow: 0px 20px 99px rgba($color: #000000, $alpha: 0.2);
      .catalogueCover {
        @apply opacity-100;
      }
      img {
        @apply scale-150;
      }
    }
  }
  &__image-container {
    @apply relative w-full h-[70vh] bg-grey overflow-hidden border-[1px] border-gray-200 hover:-translate-y-10 transition-all duration-700 cursor-pointer;
    &-picture {
      @apply w-full h-full;
    }
    .catalogueCover {
      @apply duration-500 absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] opacity-0 flex flex-col items-center justify-center gap-4;
      h3 {
        @apply text-white text-2xl font-semibold font-poppins;
      }
      button {
        @apply bg-white text-sm px-4 py-2 rounded-full font-semibold;
      }
    }
  }
}
