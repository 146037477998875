@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-black: #1d1d1f;
  --fontText: "SF Pro text";
  --fontDisplay: "SF Pro Display";
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-weight: normal;
  color: var(--color-black);
  font-family: var(--fontDisplay);
}

p,
span {
  color: var(--color-black);
  font-family: var(--fontText);
}
