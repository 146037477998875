.ReferenceDetailContent {
  @apply row-start-2 row-end-4 col-start-1 col-end-3 px-4 sm:px-16 pt-12 flex flex-col gap-4;
  &-yearPriceContainer {
    @apply w-2/3 flex flex-row items-center justify-between;
    span {
      @apply font-medium text-lg;
    }
  }
  h2 {
    @apply text-xl text-primary;
  }
  & > p {
    @apply w-full sm:w-3/4 text-sm leading-6 text-grey;
  }
  &__socialMedia {
    @apply flex flex-row items-center gap-4 text-3xl text-primary mt-8;
    .social-icon {
      @apply hover:scale-150 duration-500 ease-out;
    }
  }

  .backBtn {
    @apply hidden sm:block self-start mt-auto mb-8 text-grey underline;
  }
}
