.CircleNav {
  @apply duration-500 w-[52px] h-[52px] sticky left-4 top-1/2 bg-white rounded-full drop-shadow-big flex justify-center items-center z-50;
  &:hover {
    @apply opacity-100;
  }
  &.expand {
    .item {
      .icon {
        @apply text-primary;
      }
      &-1 {
        @apply opacity-100;
        transform: translateY(calc(-130px));
      }
      &-2 {
        @apply opacity-100;

        transform: translateY(calc(50% - 130px)) translateX(110%);
      }
      &-3 {
        @apply opacity-100;

        transform: translateY(calc(130% - 130px)) translateX(200%);
      }
      &-4 {
        @apply opacity-100;

        transform: translateY(calc(250% - 130px)) translateX(230%);
      }
      &-5 {
        @apply opacity-100;

        transform: translateY(calc(368% - 130px)) translateX(200%);
      }
      &-6 {
        @apply opacity-100;

        transform: translateY(calc(450% - 130px)) translateX(110%);
      }
      &-7 {
        @apply opacity-100;

        transform: translateY(calc(130px));
      }
    }
  }
  .item {
    @apply duration-[600ms] ease-out absolute w-[52px] h-[52px] bg-white rounded-full flex items-center justify-center opacity-0 text-primary text-2xl;
    background-image: linear-gradient(to bottom, blue, blue);
    background-repeat: no-repeat;
    background-position: 100% 120%;
    background-size: 100% 0.4rem;

    &:not(.z-10):hover {
      background-image: linear-gradient(to bottom, blue, blue);

      background-size: 100% 100%;
      .icon {
        @apply duration-300 text-white;
      }
    }
  }
}
