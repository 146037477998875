.References {
  @apply w-screen h-screen sm:px-12 pt-12 flex flex-col items-center mt-32;
  h2 {
    @apply flex flex-col items-center gap-y-2 text-3xl;
  }
  p {
    @apply text-center font-normal leading-5 text-sm text-grey;
    white-space: pre-line;
  }
  &-item__container {
    @apply snap-x snap-mandatory overflow-x-scroll overflow-y-hidden w-full h-[50vh] grid grid-rows-1 grid-flow-col gap-12 sm:gap-32  relative;

    &:hover {
      &::-webkit-scrollbar-thumb {
        @apply bg-primary;
      }
      @apply overflow-x-scroll;
    }
    &::-webkit-scrollbar {
      @apply h-[7px] hidden;
    }

    &::-webkit-scrollbar-track {
      transition: all 1s;
      @apply bg-slate-400 rounded-full;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-transparent rounded-xl;
    }
  }
}
