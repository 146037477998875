.EcoChantiers {
  @apply px-4 sm:px-8;
  @apply min-h-fit mb-12 sm:mb-0 sm:min-h-screen flex flex-col items-center justify-start relative;

  img.leaf-illustration {
    @apply absolute hidden sm:block h-full left-0 -translate-x-1/2 object-cover;
  }
  h2 {
    @apply flex items-center gap-2 text-[35px] sm:text-[49px];

    font-weight: 300;
    text-align: left;
    color: #000;

    span.letter {
      color: #00ae73;
    }
  }

  .EcoChantiers-content {
    @apply flex flex-col gap-4 sm:gap-0 items-center w-full sm:w-2/3;
    li {
      @apply flex flex-col gap-4 sm:gap-0 sm:flex-row items-center justify-between w-full;
      &:nth-child(odd) {
        @apply flex-col sm:flex-row-reverse;
        p {
          &:nth-of-type(1) {
            @apply justify-start;
            span {
              @apply text-center sm:text-left sm:pl-2;
            }
          }
          &:nth-of-type(2) {
            @apply justify-end text-center sm:text-right;
          }
        }
      }
      &:nth-child(even) {
        p:nth-of-type(2) {
          @apply text-center sm:text-left;
        }
      }
      p {
        @apply w-full sm:w-6/12 sm:py-8 flex;
        span {
          @apply text-center sm:text-right sm:pr-9 py-2 w-full rounded-md;
          background-color: rgba($color: #0f980f, $alpha: 0.39);
          font-weight: 500;
          font-size: 14px;
          line-height: 27px;
          // text-align: right;
          color: #5a8073;
        }
        &:nth-child(1) {
          @apply justify-end;
        }
      }
      .separator {
        @apply hidden sm:flex w-2/12 self-stretch  items-center justify-center relative;
        .bulle {
          @apply w-8 h-8 rounded-full border-2 border-[#009200] z-10 bg-white  flex items-center justify-center relative;
          &::before {
            @apply w-6 h-6 border-2 border-[#009200] rounded-full absolute left-1/2 -translate-x-1/2;
            content: "";
          }
        }
        &::before {
          content: "";
          @apply w-1 h-full  bg-[#009200] absolute left-1/2 -translate-x-1/2;
        }
      }
    }
  }
}
