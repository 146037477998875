.ImgZoom {
  @apply overflow-hidden relative;
  & > div {
    @apply z-10;
    &:hover + img {
      @apply scale-150;
    }
  }
  img {
    @apply w-full h-full object-cover hover:scale-150  duration-500;
  }
}
