.EcoMateriaux {
  @apply px-6 sm:px-8;
  @apply min-h-screen flex flex-col sm:flex-row items-center justify-evenly;
  img {
    @apply w-2/3 sm:w-1/2 object-cover;
  }
  .EcoMateriaux-content {
    @apply w-full  sm:w-5/12 flex flex-col gap-4;
    & > p:nth-child(1) {
      @apply flex items-center gap-2;
      span {
        font-weight: 300;
        font-size: 20px;
        text-align: left;
        color: #5a8073;
      }
      .leaf-icon {
        @apply text-[#00AE73] text-3xl;
      }
    }
    h2 {
      @apply flex items-center text-[35px] sm:text-[49px] gap-2;
      font-weight: 300;
      text-align: left;
      color: #00ae73;
      & > span {
        .letter {
          &:nth-child(1) {
            color: #00ae73;
          }
          &:nth-last-child(1) {
            color: #00ae73;
          }
        }
      }
    }

    ul.EcoMateriaux-liste {
      @apply flex flex-col gap-4;
      li {
        @apply flex items-center gap-2 pb-4;
        background: linear-gradient(#00ae73, #00ae73) no-repeat bottom center;
        background-size: 90% 2%;
        p:nth-child(1) {
          @apply w-12 h-12 flex items-center justify-center;
          background-color: rgba($color: #00ae73, $alpha: 0.38);
          font-weight: bold;
          font-size: 25px;
          text-align: left;
          color: #202020;
        }
        p:nth-child(2) {
          @apply w-fit;
          font-weight: normal;
          font-size: 14px;
          text-align: left;
          color: #5a8073;
        }
      }
    }

    .right-icon {
      @apply self-end;
      font-weight: 300;
      font-size: 49px;
      text-align: left;
      color: #00ae73;
    }
  }
}
