.ReferenceDetailVideoPlayer {
  @apply w-full h-full  sm:h-full bg-grey sm:col-start-1 sm:col-end-6 relative;
  &-videoCover {
    @apply w-full h-[40vh] sm:h-full relative block;
    // &::after {
    //   @apply content-[""] absolute top-0 w-full h-full bg-black opacity-50;
    // }
    // img {
    //   @apply w-full h-full object-cover;
    // }
    &__btnPlay {
      @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-16 h-16 border-2 border-white z-10 flex items-center justify-center rounded-full cursor-pointer;
    }
    h1 {
      @apply absolute top-4 sm:top-1/2 sm:-translate-y-1/2 translate-x-4 sm:translate-x-20 text-5xl text-white z-10;
    }
  }
}
