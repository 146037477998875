.EquipesItem {
  @apply w-24  min-h-[400px] bg-red-500 rounded-lg duration-500 cursor-pointer overflow-hidden relative flex flex-col;
  &.isExpand {
    @apply w-[300px] border-none;
  }
  &-profile {
    @apply w-full h-full object-cover absolute top-0 left-0;
  }
  &-content {
    @apply w-full h-2/4 bg-[rgba(0,0,0,0.71)] z-50 relative mt-auto rounded-t-lg flex flex-col;
    h2 {
      @apply font-semibold flex items-center relative  gap-2;
      span {
        @apply text-white;
      }
    }
    &__main {
      &-poste {
        @apply text-white font-poppins text-sm;
        span {
          @apply text-lightGreen;
        }
      }
      &-descriptionPoste {
        @apply text-xs text-white w-[275px] font-light mt-4 border-b-[1px] border-b-white;
        &:last-of-type {
          @apply border-none mt-2;
        }
      }
    }
  }
}
