.agences__container {
  @apply mt-8 min-w-full flex flex-col px-4 sm:pl-24 sm:pr-12 relative max-w-full;
  // @apply flex flex-row;

  hr {
    @apply hidden;
  }
  &-menu {
    // overscroll-behavior: contain;
    @apply w-full  flex flex-row overflow-hidden;
  }
}

@media screen and (max-width: 1024px) {
  .agences__container-menu {
    &__left-item {
      // @apply w-1/2;
    }
    @apply gap-6;
    hr {
      @apply block w-full h-[1px] bg-black mt-8;
    }
  }
}

@media screen and (max-width: 460px) {
  .agences__container-menu {
  }
}
