.Footer {
  @apply w-screen px-8 bg-[#232C29] py-12 overflow-hidden;
  .Footer-top {
    @apply flex flex-col-reverse gap-8 lg:flex-row;
    .left {
      @apply flex flex-col items-start gap-4;
      h2 {
        // font-family: Poppins;
        font-weight: 500;
        font-size: 38px;
        // line-height: 69px;
        text-align: left;
        color: #fff;
      }
      .agence-detail {
        @apply flex flex-col items-start gap-4;
        @apply border-l-2 border-[#fff] pl-4 mb-8;
        p.title {
          font-weight: normal;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          color: #fff;
        }
        p {
          @apply flex  w-full items-start gap-2;
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          text-align: left;
          color: #fff;
          span {
            @apply w-8/12;
            color: #fff;
          }
          .icon {
            @apply text-2xl w-2/12;
          }
        }
      }
    }

    .right {
      @apply flex flex-col items-start gap-8  lg:justify-around;
      img {
        @apply w-[80%] object-cover;
      }

      .message-container {
        @apply w-full lg:w-3/4 self-end;
        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 32px;
          text-align: left;
          color: #fff;
        }

        .input-container {
          @apply flex items-center bg-[#fff] rounded-md gap-4 pl-4;
          input {
            @apply w-full outline-none h-full border-none bg-transparent py-2;
          }

          .icon-container {
            @apply self-stretch bg-[#3AFCE8] w-12  rounded-md flex items-center text-[#3B544C] justify-center;
          }
        }
      }
    }
  }

  .Footer-bottom {
    @apply w-full;
    ul {
      @apply w-full flex flex-col items-start justify-between gap-8 py-8 sm:flex-row sm:justify-between sm:flex-wrap;
      background: linear-gradient(#fff, #fff);
      background-repeat: no-repeat;
      background-size: 80% 2px;
      background-position: top center;
      li {
        @apply w-full sm:w-auto flex items-start gap-4;
        p {
          @apply w-10/12;
          font-weight: 300;
          font-size: 16px;
          //   line-height: 50px;
          text-align: left;
          color: #fff;
        }

        .icon {
          @apply w-2/12 text-white text-2xl;
        }
      }
    }
  }
}
