.ReferencesItem {
  @apply w-[270px] h-[48vh] cursor-pointer snap-center;
  &-image {
    @apply w-full h-[43%] rounded-t-xl object-cover;
  }
  &-subTitle__container {
    @apply flex flex-row items-center justify-between mt-5;
    button {
      @apply px-6 py-1 bg-[#E6E6E6] text-primary font-medium rounded-sm text-sm;
    }
    h5 {
      @apply text-grey font-medium text-lg;
    }
  }
  h4 {
    @apply mt-4 font-medium;
  }

  p {
    @apply text-justify text-xs mt-2 text-grey leading-5;
  }
}
