.agences__container-menu__detail {
  @apply w-screen sm:min-w-[36vw] sm:max-w-[36vw] h-[100%] origin-left  py-28  max-h-screen overflow-y-scroll scroll-smooth;
  &::-webkit-scrollbar {
    @apply hidden;
  }
  &-title {
    @apply w-fit  text-5xl leading-[65px] font-medium;
    img {
      @apply w-12 h-7 inline ml-2 object-cover shadow-xl;
    }
  }
  section {
    @apply mt-4;
    h2 {
      @apply text-[29px] tracking-wide mb-4;
    }
    p {
      @apply flex flex-col gap-8 text-sm leading-6 font-light;
    }

    &:nth-of-type(2) {
      .agences__container-menu__detail-mission {
        @apply grid grid-cols-2 grid-flow-row gap-y-4 gap-x-3;
        &__item {
          @apply flex flex-col gap-4 justify-start;
          h3 {
            @apply text-[14px] flex flex-col relative min-h-[8vh] justify-center;
            &::after {
              @apply relative content-[""] w-1/3 h-[0.2vh] bg-black;
            }
          }
          p {
            @apply text-xs leading-5;
          }
        }
      }
    }
    &:nth-of-type(3) {
      p {
        @apply gap-0;
      }
    }
  }
}
